import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminWorkshopGuard } from '../core/route-guards/admin-workshop.guard';
import { InventoryComponent } from './inventory.component';

const routes: Routes = [
    {
        path: '',
        component: InventoryComponent,
        canActivate: [AdminWorkshopGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InventoryRoutingModule { }