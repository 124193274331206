export enum BikeDisableState {
    ToWorkshop = 1,
    InWorkshop = 2,
    RepairFinished = 3,
    InStorage = 4,
    Offline = 10,
    PositionLost = 11,
    LowPower = 12,
    Moving = 21,
    Testing = 23,
    CheckRequired = 22,
    Missing = 24,
    CheckedNeedFix = 25
}