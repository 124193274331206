export const environment = {
    production: true,
    title: 'brakar.betabike.no',
    mcs_template: 'BETABIKEB',
    main_url: 'https://api.brakar.betabike.no',
    api_url: 'https://api.brakar.betabike.no/api',
    s3_image_url: 'https://mcs-betabike.s3-eu-west-1.amazonaws.com/',
    logo_title: 'BetaBike - Brakar',
    map_center_position: [10.203262316063046, 59.743094819663355],
    enable_insight: false
};
